// TODO ADD EVENTS patient_registered forgot_password_started forgot_password_completed
// PAGE TITLES CLEAR ONE WITHOUT VENDOR THERE
// ADD SHIPPING TO TOTALS ????
// ADDING STEPS patient registration,
import * as Sentry from '@sentry/nextjs'
import { askEvaroLogClient } from '@services/client/discovery/log'
import { type CartItem, useCheckoutStore } from 'store/checkout'
import { usePatientStore } from 'store/patient'
import type { iProduct } from 'types/product'
import { isStorageAvailable } from './storage'

const formatItem = (item: any, options?: { item_list_id?: string; item_list_name?: string }) => {
  const listInfo = options?.item_list_id ? options : getStoredItemListInfo(item)
  let variation
  let item_name
  if ('variation' in item) {
    variation = item.variation?.find((e: { attribute: string; label: string }) => e.attribute === 'pa_ep_frequency')?.label || 'One Time Purchase'
    item_name = `${item.title} [${item.variation?.map((e: { label: string }) => e.label).join(', ')}]`.toLowerCase()
  } else if ('variations' in item) {
    item_name = `${item.name} [${Object.values(item.variations).join(', ')}]`.toLowerCase()
    variation = item.variations.frequency
  } else if ('title' in item) {
    item_name = item.title
  }

  return {
    item_id: 'id' in item ? item.id : item.hash,
    item_name: item_name,
    price: ('price' in item ? item.price : item.total) / 100,
    quantity: 1,
    item_variant: variation,
    item_list_id: listInfo?.item_list_id,
    item_list_name: listInfo?.item_list_name
  }
}

function gtag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

const logAll = (event: string, data: Record<string, any> = {}) => {
  data.timestamp = new Date().toISOString()
  // @ts-ignore
  gtag('event', event, data)

  if (!data.timestamp) {
    Sentry.captureMessage(`Missing timestamp for ${event}`, {
      level: 'warning',
      contexts: {
        event: {
          event,
          data
        }
      }
    })
  }

  window.parent.postMessage(
    {
      type: 'EVARO_ANALYTIC_EVENT',
      data: {
        event: `evaro_${event}`,
        data
      }
    },
    '*'
  )
}

export const logViewItem = (item: iProduct) => {
  logAll('view_item', {
    currency: 'GBP',
    item_name: item.title
  })
}

export const logViewItemList = (items: iProduct[], options: { item_list_id: string; item_list_name: string }) => {
  logAll('view_item_list', {
    currency: 'GBP',
    items: items.map(item => formatItem(item, options))
  })
}

export const logSelectItem = (item: iProduct, options: { item_list_id: string; item_list_name: string }) => {
  storeSelectedItem(item.hash, options.item_list_id, options.item_list_name)

  logAll('select_item', {
    currency: 'GBP',
    items: [formatItem(item, options)]
  })
}

export const logAddToCart = (item: CartItem) => {
  logAll('add_to_cart', {
    currency: 'GBP',
    value: item.price / 100,
    items: [formatItem(item)]
  })
}

export const logViewCart = () => {
  logAll('view_cart', {
    currency: 'GBP',
    value: useCheckoutStore.getState().getCartTotal() / 100,
    items: useCheckoutStore.getState().cart.map(item => formatItem(item))
  })
}

export const logRemoveFromCart = (item: CartItem) => {
  logAll('remove_from_cart', {
    currency: 'GBP',
    value: item.price / 100,
    items: [formatItem(item)]
  })
}

export const logBeginCheckout = () => {
  logAll('begin_checkout', {
    currency: 'GBP',
    value: useCheckoutStore.getState().getCartTotal() / 100,
    items: useCheckoutStore.getState().cart.map(item => formatItem(item)),
    coupon: useCheckoutStore.getState().coupon?.code
  })
}

export const logPurchase = (order: any) => {
  logAll('purchase', {
    transaction_id: order.meta.order_number,
    value: Number.parseFloat(order.payment.total) / 100,
    discount: order.payment.discount,
    sub_total: Number.parseFloat(order.payment.sub_total) / 100,
    shipping: Number.parseFloat(order.payment.shipping) / 100,
    shipping_method: order.shipping.method_title,
    currency: 'GBP',
    coupon: order.payment.coupons?.[0],
    items: order.items.map(formatItem)
  })
  order.items.forEach(deleteStoredItem)
}

export const logLogout = () => {
  logAll('logout', {
    login_status: 'logged_out'
  })
}

export const logLogin = () => {
  logAll('login', {
    method: 'email',
    login_status: 'logged_in'
  })
}

export const logLoginError = (method: string, error: string) => {
  logAll('login_failed', {
    method,
    error_info: error
  })
}

export const logSignUp = () => {
  logAll('sign_up', {
    method: 'email'
  })
}

export const setUserId = (userId: string) => {
  // gtag("set", { user_id: userId });
}

export const logDarkMode = () => {
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  // @ts-ignore
  gtag('set', 'user_properties', {
    dark_mode: darkMode ? 'true' : 'false',
    incognito_mode: isStorageAvailable() ? 'false' : 'true'
  })
}

export const logScreenREsolution = () => {
  // @ts-ignore
  gtag('set', 'screen_resolution', `${window.screen.width}x${window.screen.height}`)
}

export const logConsultationStarted = () => {
  logAll('consultation_started', {
    questionnaire_hash: usePatientStore.getState().questionnaire_id
  })
}

export const logConsultationCompleted = () => {
  logAll('consultation_completed', {
    questionnaire_hash: usePatientStore.getState().questionnaire_id
  })
}

export const logQuestionViewed = (question: string, consultation: string, skipped: boolean = false) => {
  logAll('question_viewed', {
    question_hash: question,
    consultation_hash: consultation,
    is_skipped: skipped
  })
}

export const logQuestionAnswered = (question: string, consultation: string, skipped: boolean = false) => {
  logAll('question_answered', {
    question_hash: question,
    consultation_hash: consultation,
    is_skipped: skipped
  })
}

let prevPage = globalThis.document?.referrer ?? ''

export const logPageView = () => {
  window.parent.postMessage(
    {
      type: 'EVARO_ANALYTIC_EVENT',
      data: {
        event: 'evaro_page_view',
        data: {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
          page_referrer: prevPage,
          timestamp: new Date().toISOString()
        }
      }
    },
    '*'
  )

  prevPage = window.location.href
}

export const logAddShippingInfo = () => {
  logAll('add_shipping_info', {
    shipping_type: useCheckoutStore.getState().shippingType?.title
  })
}

export const logAddPaymentInfo = (type: string) => {
  logAll('add_payment_info', {
    payment_type: type
  })
}

export const logViewMenu = () => {
  logAll('view_menu')
}

export const logViewLogin = () => {
  logAll('view_login')
}

export const logViewSignUp = () => {
  logAll('view_signup')
}

export const logViewForgotPassword = () => {
  logAll('view_forgot_password')
}

export const logViewNewPassword = () => {
  logAll('view_new_password')
}

export const logViewOTP = () => {
  logAll('view_otp')
}

export const logProductChoice = (data: any) => {
  logAll('product_choice', data)
}

export const logDiscoveryOpened = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_initiated', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  askEvaroLogClient('Discovery Opened')
}

export const logDiscoveryClosed = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_closed', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  askEvaroLogClient('Discovery Closed')
}

export const logDiscoveryInputSubmitted = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_query_submitted', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
}

export const logDiscoveryResetButton = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_history_cleared', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  askEvaroLogClient('Reset button clicked')
}

export const logDiscoveryLinkClick = (text: string, data?: any) => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_result_clicked', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  askEvaroLogClient(text, data && JSON.stringify(data, null, 2))
}

export const logDiscoveryDialogRequest = (input: string) => {
  askEvaroLogClient(`Dialog: request - "${input}"`)
}

export const logDiscoveryDialogResponse = (data: any) => {
  askEvaroLogClient(`Dialog: response - "${data.action}"`, JSON.stringify(data, null, 2))
}

export const logConsultationView = (number: number, all: number) => {
  logAll('consultation_viewed', {
    question_number: `Q-${number}`,
    question_number_of_total: `Q-${number} of ${all}`
  })
}

export const logRecoveredCartSuccess = () => {
  logAll('recovered_cart_success')
}

export const logRecoveredCartFailure = () => {
  logAll('recovered_cart_error')
}

export const logAppClosed = () => {
  logAll('app_closed')
}

// Helper function to manage selected items storage
const storeSelectedItem = (itemHash: string, listId: string, listName: string) => {
  try {
    const now = new Date()
    const item = {
      itemHash,
      listId,
      listName,
      timestamp: now.getTime()
    }

    localStorage.setItem(`selected_item_${itemHash}`, JSON.stringify(item))
  } catch (error) {
    console.warn('Failed to store selected item:', error)
  }
}

// Helper to get stored item info
export const getStoredItemListInfo = (item: any) => {
  try {
    let hash = 'id' in item ? item.id : item.hash
    let parentHash
    if ('parentId' in item) {
      parentHash = item.parentId
    } else if ('parent_hash' in item) {
      parentHash = item.parent_hash
    }
    let stored = localStorage.getItem(`selected_item_${hash}`)
    if (!stored) {
      hash = parentHash
      stored = localStorage.getItem(`selected_item_${hash}`)
    }

    const storedItem = JSON.parse(stored)
    const now = new Date().getTime()

    // Remove if older than 24 hours
    if (now - storedItem.timestamp > 24 * 60 * 60 * 1000) {
      localStorage.removeItem(`selected_item_${hash}`)
      return null
    }

    return {
      item_list_id: storedItem.listId,
      item_list_name: storedItem.listName
    }
  } catch {
    return null
  }
}

export const deleteStoredItem = (item: CartItem | iProduct) => {
  let hash = 'id' in item ? item.id : item.hash
  let parentHash = 'parentId' in item ? item.parentId : 'parent_hash' in item ? item.parent_hash : null
  if (hash) {
    localStorage.removeItem(`selected_item_${hash}`)
  }
  if (parentHash) {
    localStorage.removeItem(`selected_item_${parentHash}`)
  }
}
